<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Utilisateurs"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-search-user
                class="float-left"
                variant="success"
                size="sm"
                pill
              >
                Créer un utilisateur
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="users"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :text="avatarText(data.item.name)"
                  :variant="`light-primary`"
                />
              </template>
              {{ data.item.name }}
            </b-media>
          </template>
          <template #cell(enable)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${enableUserVariant(data.item.enable)}`"
              >
                {{ data.item.enable }}

              </b-badge>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Voir le détail de l\'utilisateur'"
              variant="gradient-primary"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              :to="{ name: 'espace-super-admin.detail-utilisateur', params: { id: data.item.id }, replace: true }"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Modifier un user'"
              v-b-modal.modal-user
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Suspendre un utilisateur'"
              variant="gradient-danger"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="SuspendingUser(data.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="users.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

    <b-modal
      id="modal-user"
      ref="myModalUser"
      no-close-on-backdrop
      button-size="sm"
      :modal-class="form.id ? 'modal-warning' : 'modal-success'"
      :title="form.id ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'"
      :busy="userProcess"
      :ok-title="form.id ? 'Modifier' : 'Ajouter'"
      cancel-title="Fermer"
      cancel-variant="danger"
      :ok-variant="form.id ? 'warning' : 'success'"
      size="lg"
      @hidden="resetForm"
      @ok="submitForm"
    >
      <validation-observer ref="userFormRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nom"
                label-for="lastname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    id="lastname"
                    v-model="form.lastname"
                    :formatter="formatter"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Prénom "
                label-for="Prénom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="prénom "
                  rules="required"
                >
                  <b-form-input
                    id="firstname"
                    v-model="form.firstname"
                    :state="errors.length > 0 ? false : null"
                    size="sm"
                    placeholder="Prénom "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    size="sm"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group
                label="Téléphone"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="téléphone"
                  rules="required"
                  type="tel"
                >
                  <VuePhoneNumberInput
                    v-model="form.telephonefull"
                    default-country-code="BJ"
                    show-code-on-list
                    size="sm"
                    :translations="configPhoneNumber"
                    @update="telephonefull = $event"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Adresse"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="adresse"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="form.address"
                    :state="errors.length > 0 ? false : null"
                    size="sm"
                    :formatter="formatter"
                    placeholder="Adresse"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de naissance"
                label-for="birth_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date de naissance"
                  rules="required"
                >
                  <flat-pickr
                    id="birth_date"
                    v-model="form.birth_date"
                    :config="config"
                    class="form-control-sm mb-1"
                    placeholder="Date de naissance"
                    size="sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.id===null"
              md="6"
            >
              <b-form-group
                label="Sélectionner un rôle"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="role"
                  rules="required"
                >
                  <v-select
                    id="role"
                    v-model="form.role_id"
                    label="title"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :options="roles"
                    placeholder="Sélectionner un rôle"
                    :reduce="(roles) => roles.id"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.id===null"
              md="6"
            >
              <b-form-group
                :label="form.role_id ===2?'Sélectionner une copropriété':'Sélectionner une société'"
                label-for="copropriete"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="form.role_id ===2 ? 'copropriété' : 'société'"
                  rules="required"
                >
                  <v-select
                    id="copropriete"
                    v-model="form.entite_id"
                    :label="form.role_id === 2 ? 'name' : 'company_name'"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :options="entites"
                    :placeholder="form.role_id!==2?'Sélectionner une société':'Sélectionner une copropriété'"
                    :reduce="(entites) => entites.id"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-search-user"
      ref="myModal"
      no-close-on-backdrop
      button-size="sm"
      modal-class="modal-dark"
      title="Rechercher un utilisateur"
      :busy="userProcess"
      ok-title="Fermer"
      ok-variant="danger"
      ok-only
      no-stacking
      size="md"
      @hidden="resetForm"
    >
      <validation-observer
        #default="{ invalid }"
        ref="userFormRules"
      >
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Email ou numéro matricule du responsable"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email ou numéro matricule"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="email"
                      v-model="formSearch.email"
                      :state="errors.length > 0 ? false : null"
                      size="sm"
                      placeholder="Email ou numéro matricule du responsable"
                    />
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        variant="primary"
                        :disabled="invalid|| userProcess"
                        @click.prevent="searchUserSubmitModal"
                      >
                        Rechercher
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-list-group-item
                    v-if="user.length !== 0"
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <div class="d-flex">
                      <b-avatar
                        :text="avatarText(user.name)"
                        variant="primary"
                      />
                      <div class="ml-25">
                        <b-card-text class="font-weight-bold mb-0">
                          {{ user.name }}
                        </b-card-text>
                        <small>{{ user.email }}</small>
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-alert
                    v-if="!foundUser"
                    variant="danger"
                    class="mt-1"
                    show
                  >
                    <div class="alert-body">
                      <strong>Utilisateur introuvable</strong>
                      <b-button
                        v-b-modal.modal-user
                        size="sm"
                        variant="gradient-dark"
                        class="float-right"
                      >
                        le créer
                      </b-button>
                    </div>
                  </b-alert>
                </validation-provider>
              </b-form-group>

            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import {
  ref, onMounted, reactive, watch, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BBadge, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput, BForm, BSpinner, VBModal,
  BAvatar,
  BMedia, BAlert, BListGroupItem, BCardText,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePhoneNumberInput from 'vue-phone-number-input'
import {
  required, email,
} from '@validations'

// eslint-disable-next-line import/no-extraneous-dependencies, import/extensions
import { French } from 'flatpickr/dist/l10n/fr.js'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/composables/userService'
// eslint-disable-next-line import/no-cycle
import useRoles from '@/composables/roleService'
// eslint-disable-next-line import/no-cycle
import useCoproprietes from '@/composables/coproprieteService'

// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
// eslint-disable-next-line import/no-cycle
import useSocietes from '@/composables/societeService'

export default {
  components: {
    VuePhoneNumberInput,
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BBadge,
    BAvatar,
    BMedia,
    BAlert,
    flatPickr,
    BListGroupItem,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []

    const {
      getAllUsers, users, createUser, updateUser, loader, userProcess, userSuccess, findUserByEmailOrMatricule, user, changeAccountStatus,
    } = useUsers()
    const {
      getRolesExceptById, roles,
    } = useRoles()
    const {
      getAllSocietesByCategorieSocieteId, societes,
    } = useSocietes()
    const {
      getAllCoproprietes, coproprietes,
    } = useCoproprietes()
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'name', label: 'Noms', sortable: true },
      { key: 'matricule', label: 'Matricule', sortable: true },
      { key: 'phone', label: 'Téléphone', sortable: true },
      { key: 'enable', label: 'Status' },
      { key: 'actions' },
    ]
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y',
      altInput: true,
      enableTime: false,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }
    const showCoownership = ref(false)

    const form = reactive({
      id: null,
      lastname: '',
      firstname: '',
      email: '',
      address: '',
      birth_date: '',
      entite_id: null,
      role_id: null,
      phone: '',
      telephonefull: '',
    })

    const telephonefull = ref('')

    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), () => {
      form.phone = ''
      form.phone = telephonefull.value.formattedNumber
      // console.clear()
    })

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    const SuspendingUser = async data => {
      Swal.fire({
        title: `${data.enable === 'Actif' ? 'Suspendre' : 'Lever la suspension'}`,
        text: `Etes-vous sûr de vouloir ${data.enable === 'Actif' ? 'suspendre' : 'lever la suspension de'} cet utilisateur ?`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Valider',
      }).then(async result => {
        if (result.isConfirmed) {
          await changeAccountStatus(data.id)
          await getAllUsers()
          Swal.fire({

            title: `${data.enable === 'Actif' ? 'Suspension' : 'Levée de suspension'}`,
            text: `Utilisateur ${data.enable === 'Actif' ? 'suspendue' : 'activée'} avec succès.`,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }
    const storeUser = async () => {
      await createUser({ ...form })
      await getAllUsers()
    }
    const updatingUser = async () => {
      await updateUser({ ...form })
      await getAllUsers()
    }

    const getItem = async item => {
      form.id = item.id
      form.enable = item.enable
      form.lastname = item.lastname
      form.firstname = item.firstname
      form.email = item.email
      form.address = item.address
      // form.role_id = item.role
      form.phone = item.phone
      form.birth_date = item.birth_date
      form.telephonefull = item.phone
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    // Variable ref de validation du modal
    const userFormRules = ref('')
    // Variable ref du modal
    const myModal = ref('')
    const myModalUser = ref('')
    const managerForm = reactive({
      societe_id: null,
      manager_id: null,
    })
    const enableUserVariant = status => {
      if (status === 'Actif') return 'success'
      if (status === 'Inactif') return 'danger'
      return 'primary'
    }

    const handleSubmitModal = () => {
      userFormRules.value.validate().then(async success => {
        if (success) {
          if (form.id === null) await createUser({ ...form })
          else await updateUser({ ...form })
          if (userSuccess.value) myModalUser.value.toggle('#toggle-btn')
        }
      })
    }
    //  Fonction de soumission du modal
    const submitForm = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmitModal()
    }
    const formSearch = reactive({
      email: '',
    })
    const entites = ref([])

    onMounted(async () => {
      await getAllUsers()
      await getRolesExceptById([1, 3, 7, 5, 6])
      await getAllCoproprietes()
      entites.value = coproprietes.value
      // Set the initial number of items
      totalRows.value = users.value.length
    })

    // Fonction du création ou de modification si form.id n'est pas null
    const foundUser = ref(true)
    const searchUserSubmitModal = () => {
      userFormRules.value.validate().then(async success => {
        if (success) {
          await findUserByEmailOrMatricule({ ...formSearch })
          if (user.value.length === 0) foundUser.value = false
          else { foundUser.value = true; managerForm.manager_id = user.value.id }
        }
      })
    }

    // Reset Form
    const resetForm = () => {
      form.id = null
      form.lastname = ''
      form.firstname = ''
      form.email = ''
      form.address = ''
      form.role_id = null
      form.entite_id = null
      form.phone = ''
      form.birth_date = ''
      form.telephonefull = ''
      formSearch.email = ''
      foundUser.value = true
      user.value = []
    }
    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), () => {
      form.phone = ''
      form.phone = telephonefull.value.formattedNumber
      // console.clear()
    })
    watch(() => formSearch.email, () => {
      user.value = []
      managerForm.manager_id = null
      foundUser.value = true
    })
    watch(() => form.role_id, async () => {
      entites.value = []
      if (form.role_id === 2) {
        await getAllCoproprietes()
        entites.value = coproprietes.value
        if (entites.value.length === 0) {
          Swal.fire({

            title: 'Pas trouvé!',
            text: 'Aucune copropriété syndic n\'a été trouvée.',
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      } else if (form.role_id === 4) {
        await getAllSocietesByCategorieSocieteId(4)
        entites.value = societes.value
        if (entites.value.length === 0) {
          Swal.fire({

            title: 'Pas trouvé!',
            text: 'Aucune société d\'agence immobilière n\'a été trouvée.',
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      } else if (form.role_id === 8) {
        await getAllSocietesByCategorieSocieteId(3)
        entites.value = societes.value
        if (entites.value.length === 0) {
          Swal.fire({

            title: 'Pas trouvé!',
            text: 'Aucune société de contrôle de service n\'a été trouvée.',
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      } else if (form.role_id === 9) {
        await getAllSocietesByCategorieSocieteId(2)
        entites.value = societes.value
        if (entites.value.length === 0) {
          Swal.fire({

            title: 'Pas trouvé!',
            text: 'Aucune société de prestation de service syndic n\'a été trouvée.',
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      }
    })
    watch(form, value => {
      if (value.role === 4 || value.role === 8 || value.role === 9) {
        showCoownership.value = false
        form.entite_id = null
      }
      if (value.role === 2) {
        showCoownership.value = true
      }
    })
    return {
      myModal,
      sortOptions,
      enableUserVariant,
      getItem,
      storeUser,
      updatingUser,
      loader,
      userProcess,
      userSuccess,
      users,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      telephonefull,
      filterOn,
      config,
      fields,
      onFiltered,
      form,
      errors,
      formatter,
      resetForm,
      required,
      email,
      avatarText,
      submitForm,
      searchUserSubmitModal,
      user,
      foundUser,
      userFormRules,
      formSearch,
      roles,
      showCoownership,
      entites,
      SuspendingUser,
      myModalUser,
    }
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
